.footer-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-info h4 {
  margin-bottom: 10px;
  font-size: 30px;
}

.footer-info ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-info li {
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 5px 0;
  margin-bottom: 5px;
}

.footer-info p {
  text-align: center;
}

.hours {
  display: flex;
}
