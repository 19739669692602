$dark: #12485a;
//$light: #f9f7f7;
$warm: #DF6C53;
$offLight: #F0EFEF;
$secondary: #36718f;

@import "~bootstrap/scss/_functions.scss"; 
@import "~bootstrap/scss/_variables.scss";   //then import native bootstrap variables

$custom-colors: ( 
    "warm": $warm, 
    "offLight": $offLight
);

$theme-colors: map-merge($theme-colors, $custom-colors);

@import '~bootstrap/scss/bootstrap.scss';

.we-do-h3 {
    font-size: 3em;
    border-bottom: 4px solid $warm;
  }

  .border-10 {
    --bs-border-width: 10px;
  }

  @media (max-width: 575.98px) { 
    .we-do-h3 {
        font-size: 2.4em;
        border-bottom: 4px solid $warm;
      }
   }