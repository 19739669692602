@import url("https://fonts.googleapis.com/css2?family=Golos+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Golos Text", sans-serif;
}

body {
overflow-y: scroll;
background: url(./images/chevronbg.svg) 110% 0 repeat-y;
background-size: auto 768px;
min-height: 100vh;
}

main {
  margin-bottom: auto;
  height: 100%;
}

.josefinSans-text {
  font-family: "Inter", "Josefin Sans", sans-serif;
}

.about-header {
  font-size: 3.2rem;
}

@media screen and (max-width: 768px) {
  .about-header {
    font-size: 2.5em;
  }
}

@media screen and (max-width: 500px) {
  .about-header {
    font-size: 2em;
  }
}

.click {
  cursor: pointer;
}

@media screen and (max-width: 990px) {
    .click-sm {
      cursor: pointer;
  }
}

/* If you wanna change bg-dark colour, change the dark variable in custom.scss */
/* .bg-dark { 
  background-color: #19647e;
} */

.white-border {
  border: 10px solid white;
}

@media screen and (max-width: 768px) {
  .white-border {
    border: 6px solid white;
  }
}


.mw-100 {
  max-width: 100%;
}

.hero-header-font {
  font-size: 2.3em;
}

@media screen and (max-width: 768px) {
  .hero-header-font {
    font-size: 2em;
  }
}

.navDropDownContents {
  max-height: 0px;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  transition: 0.5s all;
  box-shadow: 0px 0px 0px #0000004d;
}

.navDropDownContainer:hover .navDropDownContents {
  max-height: 268px;
  box-shadow: 4px 4px 4px #00000071;
}

.text-shadow {
  text-shadow: 2px 2px black;
}

.shadow-inset {
  box-shadow: 0px 0px 5px black inset;
}

.shadow-strong {
  box-shadow: 2px 2px 6px 1px black;
}

.nav-link.active {
  transition: 0.5s;
}

.hero-img {
  height: 70vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position-y: 45%;
}

.map-container {
  height: 40vh;
}

.link-card {
  box-shadow: 0px 0px 3px grey;
  bottom: 0px;
  position: relative;
  transition: opacity 0.3s ease-in-out;
}

.link-card-cover {
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
}

.link-card-cover:hover {
  background-color: rgba(222, 232, 237, 0.5);
  cursor: pointer;
}

.link-card-text {
  position: relative;
  z-index: 2;
  opacity: 1;
}


